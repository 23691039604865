@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hidden {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

/* tag input type time hidden icon  */
input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
